<template>
  <div class="live-player">
    <video class="video-js live-skin vjs-big-play-centered" controls preload="auto" ref="video">
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import _videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import zh from 'video.js/dist/lang/zh-CN.json'
const videojs = window.videojs || _videojs
videojs.addLanguage('zh-CN')

export default {
  name: 'LivePlayer',
  props: {
    options: {
      type: Object,
      required: true
    },
    stream: {
      type: MediaStream
    },
    videoUrl: {
      type: String
    }
  },
  mounted() {
    this.player = videojs(this.$refs.video, this.options)
  },
  data: function() {
    return {
      player: null
    }
  },
  methods: {},
  watch: {}
}
</script>

<style lang="less" scoped>
.live-player {
  width: 100%;
  height: 100%;

  ::v-deep .video-js {
    padding-top: 0;
    height: 100%;

    .vjs-poster {
      background-size: auto;
    }

    .vjs-live-control {
      display: none;
    }

    .vjs-at-live-edge {
      display: flex;
      align-items: center;
      order: 1;
    }

    .vjs-fullscreen-control {
      order: 6;
    }

    .vjs-volume-panel {
      order: 5;
    }

    .vjs-big-play-button {
      /*display: none;*/
    }

    .vjs-big-play-button,
    .vjs-poster,
    .vjs-tech {
      outline: none;
    }

    .vjs-tech {
      pointer-events: none;
    }

    .vjs-current-time {
      display: inline-block;
      flex: 1 0 auto;
      text-align: left;
      order: 2;
    }

    .vjs-button {
      outline: none;
      cursor: pointer;
    }

    .vjs-button > .vjs-icon-placeholder:before {
      font-size: 18px;
      line-height: 35px;
    }
  }
}
</style>
