<template>
  <div class="discuss-container">
    <div class="msg-container" style="max-height: calc(100% - 150px);height: 150px;overflow: auto;">
      <vueScroll ref="vs" :ops="ops">
        <div v-for="(msg, index) in msgs" :key="index" class="msg">
          <div style="display: flex">
            <Avatar size="small" style="min-width: 26px;min-height: 26px;" :src="msg.avatar" />
            <div class="user" style="margin-right: 10px">&nbsp;&nbsp;{{ msg.send }}</div>
            <div class="content" style="white-space: normal">
              {{ msg.content }}
            </div>
          </div>
        </div>
      </vueScroll>
    </div>
    <div class="chat-box">
      <div class="input-container">
        <div style="position: relative;width: 100%;">
          <Input
            class="input"
            ref="input"
            type="textarea"
            :maxlength="30"
            v-model="imMsg.common.data"
            @on-keydown.enter.prevent="sendTextMessage(ticSdk)"
          />
          <span style="position: absolute;right: 15px;color: #6081FF;bottom: 5px">{{ 30 - imMsg.common.data.length }} / 30</span>
        </div>
        <div class="func-container">
          <JYIcon href="#icon-shezhi" />
          <Button type="primary" size="small" @click.stop="sendTextMessage(ticSdk)">发送</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TIM from 'tim-js-sdk'
import vueScroll from 'vuescroll'

export default {
  components: { vueScroll },
  props: {
    ticSdk: {
      type: Object
    },
    groupId: {
      type: String
    },
    isLogin: {
      type: Boolean
    },
    message: {
      type: Object
    }
  },
  data: function() {
    return {
      msgs: [],
      imMsg: {
        common: {
          data: '',
          toUser: ''
        },
        custom: {
          data: '',
          desc: '',
          ext: ''
        }
      },
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#fff',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      }
    }
  },
  // mounted() {
  //   for (let i = 0; i < 30; i++) {
  //     this.msgs.push({
  //       avatar: '',
  //       send: `'izhao${i + 1}`,
  //       content: `haahahah${i + 2}`
  //     })
  //   }
  //   setTimeout(() => {
  //     var div = this.$el.querySelectorAll('.__bar-is-vertical')
  //     div[0].scrollTop = 480
  //     console.log(div[0].scrollTop)
  //   }, 1000)
  // },
  methods: {
    getId() {
      this.id
    },
    clear() {
      this.imMsg.common.data = ''
      this.$refs.input.focus()
    },
    /**
     * 发送普通文本消息
     */
    sendTextMessage(tim) {
      if (!this.isLogin) {
        return this.$message.warning('直播未开始，暂不能发送消息...')
      }
      if (this.imMsg.common.data.trim().length === 0) {
        return this.$message.warning('消息不能为空', 5000)
      }
      let message = tim.createTextMessage({
        to: this.groupId,
        conversationType: TIM.TYPES.CONV_GROUP, // 群组会话
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          text: this.imMsg.common.data.trim()
        }
      })
      // send message
      tim
        .sendMessage(message)
        .then(res => {
          // 成功
          this.msgs.push({
            send: `${this.userName}`,
            avatar: this.user.avatar,
            content: res.data.message.payload.text
          })
          this.imMsg.common.data = ''
          this.$refs.vs.scrollTo({ y: this.msgs.length * 50 }, 300)
        })
        .catch(err => {
          this.$message.warning(`消息发送失败${err}`)
        })
    },
    /**
     *  收消息
     */
    sortData(message) {
      //
    },
    sendMsg(event) {
      if (this.imMsg.common.data.trim().length === 0) {
        return
      }

      if (!this.ticSdk.getImInstance().checkLogin()) {
        this.$message.warning('直播未开始，暂不能发送消息')
        return
      }

      this.loading = true
      this.disabled = true
      this.ticSdk.sendTextMessage(this.imMsg.common.data, this.imMsg.common.toUser)
      this.clear()
    },

    /**
     * 发送自定义消息
     */
    sendCustomGroupMsg() {
      if (this.imMsg.custom.data.trim().length === 0) {
        return
      }

      this.ticSdk.sendCustomTextMessage(
        {
          data: this.imMsg.custom.data,
          desc: this.imMsg.custom.desc,
          ext: this.imMsg.custom.ext
        },
        this.imMsg.common.toUser
      )
      this.clear()
    }
  },
  watch: {
    message(val) {
      this.msgs.push(val)
      this.$refs.vs.scrollTo({ y: this.msgs.length * 50 }, 300)
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/info'
    }),
    userName() {
      return this.userInfo.realName
    },
    user() {
      return this.userInfo
    }
  },
  updated() {}
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';

.discuss-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  flex: 1 1 auto;

  .msg-container {
    flex: 3 1 auto;

    .msg {
      display: flex;
      margin: 3px 10px 10px;
      text-align: left;

      .user {
        color: @primary-color;
        flex: 0 0 auto;
      }

      .content {
        word-break: break-all;
      }
    }
  }

  .chat-box {
    flex: 0 0 150px;
    border-top: 2px solid rgba(13, 15, 16, 0.5);
    display: flex;
    flex-direction: column;

    .func-container {
      width: 100%;
      border-bottom: 2px solid rgba(13, 15, 16, 0.5);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding: 10px 0 10px 10px;
      flex: 0 0 auto;

      svg {
        cursor: pointer;
      }
    }

    .input-container {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .input {
        flex: 1 0 auto;
        text-align: left;
        width: 100%;
        outline: none;
        padding: 4px 8px;

        ::v-deep textarea {
          height: 100% !important;
          resize: none;
        }
      }

      button {
        margin: 12px;
        font-size: 12px;
        padding: 4px 24px;
      }
    }
  }
}
</style>
