<template>
  <div class="paint-box">
    <div class="opt-container">
      <div class="bg-opt">
        <div class="global">
          <span>全局背景色</span>
          <ColorPicker v-model="globalBg" :editable="false" recommend @on-change="setGlobalColor" />
        </div>
        <div class="current">
          <span>当前背景色</span>
          <ColorPicker v-model="currentBg" :editable="false" recommend @on-change="setBgColor" />
        </div>
      </div>
      <div class="brush-opt">
        <div>
          <span>画笔颜色</span>
          <ColorPicker v-model="brushColor" :editable="false" recommend @on-change="setColor" />
        </div>
        <div class="brush-size">
          <span>画笔大小</span>
          <Slider v-model="brushSize" @on-change="setThin" />
        </div>
      </div>
      <div class="other-opt">
        <div class="paint">
          <Tooltip content="插入文本" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.INPUTTEXT)">
              <use xlink:href="#icon-wenzi"></use>
            </svg>
          </Tooltip>
          <Tooltip content="曲线" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.LINE)">
              <use xlink:href="#icon-quxian"></use>
            </svg>
          </Tooltip>
          <Tooltip content="直线" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.GRAPH_LINE)">
              <use xlink:href="#icon-zhixian"></use>
            </svg>
          </Tooltip>
          <Tooltip content="实心圆" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.GRAPH_OVAL_SOLID)">
              <use xlink:href="#icon-yuan"></use>
            </svg>
          </Tooltip>
          <Tooltip content="空心圆" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.GRAPH_OVAL)">
              <use xlink:href="#icon-iconfontshouye"></use>
            </svg>
          </Tooltip>
          <Tooltip content="实心矩形" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.GRAPH_RECT_SOLID)">
              <use xlink:href="#icon-juxing1"></use>
            </svg>
          </Tooltip>
          <Tooltip content="空心矩形" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.GRAPH_RECT)">
              <use xlink:href="#icon-juxing"></use>
            </svg>
          </Tooltip>
          <Tooltip content="橡皮擦" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.ERASER)">
              <use xlink:href="#icon-xiangpica1"></use>
            </svg>
          </Tooltip>
          <Tooltip content="激光笔" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.RASER)">
              <use xlink:href="#icon-jiguangbi1"></use>
            </svg>
          </Tooltip>
        </div>

        <div class="select">
          <Tooltip content="点选" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.POINTSELECT)">
              <use xlink:href="#icon-dianxuan1"></use>
            </svg>
          </Tooltip>
          <Tooltip content="框选" placement="top">
            <svg class="icon" aria-hidden="true" @click="setType(BoardSDK.DRAW_TYPE.SELECT)">
              <use xlink:href="#icon-kuangxuan1"></use>
            </svg>
          </Tooltip>
        </div>

        <div class="upload" @click="handleClickUpload">
          <input
            ref="uploadInput"
            class="custom-file-input"
            type="file"
            @change="uploadFile"
            accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, application/vnd.ms-excel"
          />
          <div v-if="!isUploading">
            上传文件
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shangchuan"></use>
            </svg>
            (图片、pdf、word、ppt、excel)
          </div>
          <Progress v-else :percent="percent" />
        </div>

        <div class="clear">
          <svg class="icon" aria-hidden="true" style="margin-right: 10px">
            <use xlink:href="#icon-qingkong"></use>
          </svg>

          <Tooltip content="清除当前页涂鸦（保留背景）" placement="top">
            <span @click="clearDraws">涂鸦</span>
          </Tooltip>
          <Tooltip content="清除当前页涂鸦和背景" placement="top">
            <span @click="clear">全部</span>
          </Tooltip>
          <Tooltip content="清除全局背景色" placement="top">
            <span @click="clearGlobal">背景</span>
          </Tooltip>
        </div>

        <div class="undo">
          <Tooltip content="撤销" placement="top">
            <svg class="icon" aria-hidden="true" @click="revert">
              <use xlink:href="#icon-chexiao1"></use>
            </svg>
          </Tooltip>
          <Tooltip content="恢复" placement="top">
            <svg class="icon" aria-hidden="true" style="transform: scaleX(-1)" @click="process">
              <use xlink:href="#icon-chexiao1"></use>
            </svg>
          </Tooltip>
        </div>
      </div>
    </div>
    <div id="paint_box"></div>
    <div class="page-opt">
      <div class="page-info">
        <span>当前页: {{ boardData.page.current }}</span>
        <span style="margin-left: 10px">总页数: {{ boardData.page.total }}</span>
      </div>
      <div class="page-btn">
        <Button type="primary" size="small" @click="prevBoard">上一页</Button>
        <Button type="primary" size="small" @click="nextBoard">下一页</Button>
        <Button type="primary" size="small" @click="addBoard">新增一页</Button>
        <Button type="primary" size="small" :disabled="boardData.page.current === 1" @click="deleteBoard">删除当前页</Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaintBox',
  props: {
    ticSdk: {
      required: true
    }
  },
  data: function() {
    return {
      globalBg: '#ffffff',
      currentBg: '#ffffff',
      brushColor: '#ff0000',
      brushSize: 50,
      isUploading: false,
      percent: 0,
      boardData: {
        data: {
          current: null,
          list: []
        },
        page: {
          current: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    resize() {
      this.ticSdk.getBoardInstance() && this.ticSdk.getBoardInstance().resize()
    },
    /**
     * 上一页
     */
    prevBoard() {
      this.ticSdk.getBoardInstance().prevBoard()
    },

    /**
     * 下一页
     */
    nextBoard() {
      this.ticSdk.getBoardInstance().nextBoard()
    },

    /**
     * 新增一页
     */
    addBoard() {
      this.ticSdk.getBoardInstance().addBoard()
    },

    /**
     * 删除当前页
     */
    deleteBoard() {
      this.ticSdk.getBoardInstance().deleteBoard()
    },

    /**
     * 设置全局背景色
     * @param {*} color Hex 色值，如 #ff00ff
     */
    setGlobalColor(color) {
      this.ticSdk.getBoardInstance().setGlobalBackgroundColor(color)
    },

    /**
     * 设置当前页背景色
     * @param {*} color Hex 色值，如 #ff00ff
     */
    setBgColor(color) {
      this.ticSdk.getBoardInstance().setBackgroundColor(color)
    },

    /**
     * 设置涂鸦颜色
     * @param {*} color Hex 色值，如 #ff00ff
     */
    setColor(color) {
      this.ticSdk.getBoardInstance().setColor(color)
    },

    /**
     * 设置涂鸦类型
     * @param {*} type
     */
    setType(type) {
      this.ticSdk.getBoardInstance().setType(type)
    },

    /**
     * 模拟点击 input
     */
    handleClickUpload() {
      this.$refs.uploadInput.click()
    },

    /**
     * 上传文件
     */
    uploadFile() {
      const file = this.$refs.uploadInput.files[0]
      const that = this

      this.isUploading = true
      this.percent = 0

      if (file && /\.(bmp|jpg|jpeg|png|gif|webp|svg|psd|ai)$/i.test(file.name)) {
        this.$message.loading('图片正在上传，请等待')
        this.ticSdk.addImgFile(
          file,
          (total, data) => {
            that.$message.success('图片上传成功')
            that.$refs.uploadInput.value = ''
          },
          err => {
            that.$message.error('图片上传失败，请重试')
            that.$refs.uploadInput.value = ''
          }
        )
      } else {
        this.$message.loading('文件正在上传，请等待')
        this.ticSdk.addFile(
          file,
          (total, data) => {
            that.$message.info('文件上传成功，共' + total + '页')
            that.$refs.uploadInput.value = ''
          },
          err => {
            that.$message.error('文件上传失败，请重试')
            this.$refs.uploadInput.value = ''
          }
        )
      }
    },

    /**
     * 设置涂鸦粗细
     * @param {*} num
     */
    setThin(num) {
      this.ticSdk.getBoardInstance().setThin(num)
      this.ticSdk.getBoardInstance().setRadius(num)
    },

    /**
     * 清空当前页涂鸦(保留背景色/图片)
     */
    clearDraws() {
      this.ticSdk.getBoardInstance().clearDraws()
    },

    /**
     * 清空当前页涂鸦 + 背景色/图片
     */
    clear() {
      this.ticSdk.getBoardInstance().clear()
    },

    /**
     * 清除全局背景色
     */
    clearGlobal() {
      this.ticSdk.getBoardInstance().clearGlobalBgColor()
    },

    /**
     * 回退
     */
    revert() {
      this.ticSdk.getBoardInstance().undo()
    },

    /**
     * 恢复
     */
    process() {
      this.ticSdk.getBoardInstance().redo()
    },

    /**
     * 白板事件回调处理
     * @param {*} data
     */
    proBoardData(data) {
      this.boardData.data.current = data.current
      this.boardData.data.list = data.list
      this.currentFile = data.currentFile

      this.boardFileGroup = this.ticSdk.getBoardInstance().getAllFileInfo()

      this.boardData.page = {
        current: this.boardData.data.list.indexOf(data.current) + 1,
        total: this.boardData.data.list.length
      }
    }
  },
  computed: {
    BoardSDK() {
      return window.BoardSDK
    }
  },
  watch: {
    ticSdk(ticSdk) {
      if (!ticSdk) {
        return
      }

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.ADD_BOARD, data => {
        this.proBoardData(data)
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.DELETE_BOARD, data => {
        this.proBoardData(data)
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.SWITCH_BOARD, data => {
        this.proBoardData(data)
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.VERIFY_SDK_SUCC, () => {})

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.VERIFY_SDK_ERROR, () => {
        this.$message.error('白板SDK鉴权不通过')
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.IMG_START_LOAD, data => {
        this.$message.loading('开始加载图片')
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.IMG_LOAD, data => {
        this.$message.success('图片加载成功')
        this.isUploading = false
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.IMG_ERROR, data => {
        this.$message.error('图片加载失败')
        this.isUploading = false
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.BOARD.IMG_ABORT, data => {
        this.$message.warning('图片中断加载')
        this.isUploading = false
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.COS.TASK_READY, data => {})

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.COS.HASH_PROGRESS, data => {})

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.COS.PROGRESS, data => {
        this.percent = Math.round(data.percent * 100)
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.COS.GET_SIGN_ERROR, data => {
        this.$message.error('获取sigin错误')
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.COS.GET_SIGN_SUCCESS, data => {})

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.COS.UPLOAD_SUCCESS, data => {
        this.$message.loading('文件上传完成，正在获取文件总页数~')
      })

      this.ticSdk.on(TICSDK.CONSTANT.EVENT.COS.UPLOAD_ERROR, data => {
        this.$message.error('上传失败')
        this.isUploading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.paint-box {
  background-color: #282b2d;
  padding: 10px;
  left: 0;
  top: 2px;
  width: 80%;
  height: calc(100% - 4px);
  color: white;
  text-align: left;
  display: flex;
  position: absolute;
  flex-direction: column;

  ::v-deep .opt-container {
    .ivu-color-picker {
      margin-left: 10px;
      cursor: pointer;

      .ivu-input-icon {
        display: none;
      }

      .ivu-color-picker-focused {
        box-shadow: none;
      }

      .ivu-color-picker-input {
        border: none;
        padding: 0;
        height: auto;
        cursor: pointer;

        .ivu-color-picker-color {
          top: 0;
        }
      }
    }

    .ivu-btn-small {
      font-size: 12px;
    }

    .bg-opt {
      display: flex;

      .current {
        margin-left: 20px;
      }
    }

    .brush-opt {
      display: flex;
      align-items: center;

      .brush-size {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        margin-left: 20px;

        .ivu-slider {
          width: 50%;
          margin-left: 10px;
          max-width: 200px;
        }
      }
    }

    .other-opt {
      display: flex;
      justify-content: space-between;

      .ivu-tooltip {
        padding: 0 6px;
        cursor: pointer;
      }

      .select {
        margin-left: 20px;
      }

      .upload {
        cursor: pointer;
        position: relative;
        min-width: 100px;

        input {
          display: none;
        }
      }
    }
  }

  ::v-deep #paint_box {
    flex: 1 1 auto;
    background-color: white;
    border: 1px solid rgba(161, 36, 28, 1);
    position: relative;
    margin-top: 10px;

    .tx_board_canvas_wrap {
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
    }
  }

  .page-opt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .ivu-btn-small {
      font-size: 12px;
    }

    .page-btn {
      .ivu-btn {
        margin-left: 10px;
      }
    }
  }
}
</style>
