<template>
  <div class="tab-container">
    <div class="tab-navs">
      <div class="tab-nav" :class="{ active: currentTab === 'discuss' }" @click="currentTab = 'discuss'">
        讨论
      </div>
      <div class="tab-nav" :class="{ active: currentTab === 'member' }" @click="currentTab = 'member'">
        <!--        成员({{ memberList.length }})-->
        成员
      </div>
    </div>
    <div class="content" style="height: calc(100% - 43px);">
      <transition name="fade" mode="out-in">
        <LiveDiscuss v-if="currentTab === 'discuss'" :tic-sdk="ticSdk" :groupId="groupId" :isLogin="isLogin" :message="msgs" />
        <LiveMember v-else :tic-sdk="ticSdk" :groupId="groupId" :isLogin="isLogin" :memberList="memberList" />
      </transition>
    </div>
  </div>
</template>

<script>
import LiveDiscuss from './discuss/LiveDiscuss'
import LiveMember from './member/LiveMember'
export default {
  components: {
    LiveDiscuss,
    LiveMember
  },
  props: {
    ticSdk: {
      type: Object
    },
    groupId: {
      type: String
    },
    isLogin: {
      type: Boolean
    },
    msgs: {
      type: Object
    }
  },
  data: function() {
    return {
      currentTab: 'discuss',
      memberList: []
    }
  },
  watch: {
    //currentTab = 'member'
    currentTab(val) {
      if (val === 'member' && this.isLogin) {
        // 获取群组成员
        this.ticSdk
          .getGroupMemberList({
            groupID: `${this.groupId}`,
            count: 100,
            offset: 0
          })
          .then(res => {
            this.memberList = res.data.memberList
          })
          .catch(err => {
            this.$message.error('群成员查找失败' + err)
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/variables';

.tab-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tab-navs {
    flex: 0 0 auto;
    display: flex;

    .tab-nav {
      width: 50%;
      padding: 10px 20px;
      color: white;
      font-size: 14px;
      border-bottom: 2px solid rgba(13, 15, 16, 0.5);
      cursor: pointer;

      &.active {
        border-bottom-color: @primary-color;
      }
    }
  }

  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
